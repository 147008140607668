function Footer() {
  return (
    <footer className="footer">
      <p>Copyright © 2023</p>
      <p>
        Designed by <span>STRIKELAB</span>
      </p>
    </footer>
  );
}

export default Footer;
