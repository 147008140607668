function FocusText() {
  return (
    <p>
      We are Professional in
      <span className="focus-text"></span>
    </p>
  );
}

export default FocusText;
